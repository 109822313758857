<template>
  <div class="button-group" :class="{'button-group__disabled': disable}">
    <div v-for="(option, key) in options" v-bind:key="key" :class="{'button-group__element__active': value === key}"
         class="button-group__element" @click="disable ? ()=>{} : changeValue()">
      {{ option }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'button-toggle',
  props: {
    options: {
      type: Array,
    },
    value: {
      type: Number
    },
    disable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    changeValue() {
      this.$emit('click')
    },
  },
}
</script>

<style lang="scss">
.button-group {
  display: flex;
  width: fit-content;
  padding: 5px;
  background-color: white;
  border: 1px solid #e3e9ed;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1rem;

  &__element {
    padding: 9px 24px;
    cursor: pointer;
    color: #8d9ca8;
    white-space: nowrap;

    &:hover {
      color: black;
    }

    &__active {
      background-color: #e3e9ed;
      color: black;
      border-radius: 4px;
    }
  }

  &__disabled {
    background-color: #f2f7fa;

    .button-group__element {
      color: #8d9ca8;

      &:hover {
        color: #8d9ca8;
      }
    }
  }
}
</style>
