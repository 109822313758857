<template>
  <div class="column md12">
    <div class="column flex md4 sm6 xs12">
      <div v-for="categories in permissionsByCategory"
           :key="categories.name"
           class="flex">
        <h1 class="category__header">{{ categories.name }}</h1>
        <div class="inline__buttons">
          <div v-for="(p) in categories.permissions"
               :key="p.name"
               class="flex">
            <label :for="p.name">{{ p.description }}</label>
            <ButtonToggle
              :options="[p.enableText, p.disableText]"
              :disable="disableRules(p.id)"
              :value="permissionsCheckboxes[p.id] ? 0 : 1"
              @click="togglePermission([p.id])"
            ></ButtonToggle>
          </div>
        </div>
      </div>

      <div class="flex">
        <h1 class="category__header">Categories settings</h1>
        <div class="inline__buttons">
          <div :key="'Select_permission_method'"
               class="flex">
            <label :for="'Select_permission_method'">Select permission method</label>
            <ButtonToggle
              :options="['Allowed categories', 'Excluded categories']"
              :value="categoriesPermissionType ? 1 : 0"
              @click="toggleCategoriesPermissionType"
            ></ButtonToggle>
          </div>
        </div>
      </div>

    </div>
    <div class="column flex md12 sm12 xs12">
      <div class="flex">
        <h1 class="category__header">{{ categoriesPermissionType ? "Allow " : "Exclude " }} all categories except:</h1>
        <div
          v-for="category in categoriesList"
          :key="category.id"
          class="category__item">
          <Trash @click="onOpenDeleteModal(category.id)"/>
          <va-icon v-if="categoriesPermissionType" name="material-icons" style="color: #db4545;">
            remove_done
          </va-icon>
          <va-icon v-else name="material-icons" style="color: #45db54;">
            done_all
          </va-icon>
          {{
            category.label
          }}
        </div>

        <div class="row">
          <div
            class="category__button"
            @click="onOpenAddModal"
          ><span>+</span> Add new {{ categoriesPermissionType ? " excluded " : " allowed " }} category
          </div>
          <div
            :style="{'margin-left': '5px'}"
            class="category__button"
            @click="onOpenDeleteModal(-1)"
          > Delete all categories
          </div>
        </div>

      </div>
    </div>

    <Portal to="body">
      <the-modal v-if="isDeleteModal" @close="onCloseDeleteModal">
        <div class="the-modal__title">Warning</div>
        <div class="the-modal__text">Are you sure you want to remove {{this.toDeleteId === -1 ? " all categories " : " this category "}} from the
          {{ categoriesPermissionType ? " excluded " : " allowed " }} list?
        </div>
        <div class="the-modal__buttons">
          <button class="the-modal__button the-modal__button--black" @click="onConfirmDeleteModal">Yes, delete</button>
          <button class="the-modal__button" @click="onCloseDeleteModal">Cancel</button>
        </div>
      </the-modal>
    </Portal>

    <Portal to="body">
      <the-modal v-if="isAddModal" @close="onCloseAddModal">
        <div class="the-modal__title">Add new allowed category</div>
        <div :style="{'min-height': '30vh'}" class="flex">
          <treeselect
            v-model="searchCategories"
            :clearable="true"
            :close-on-select="false"
            :async="true"
            :limit="1"
            :multiple="true"
            :load-options="loadSearchCategories"
            :searchable="true"
            :value-consists-of="LEAF_PRIORITY"
            placeholder="Category"
            search-nested
          >
            <div slot="option-label" slot-scope="{ node }">
              <span :title="node.label">{{
                  node.label.length > 25 ? node.label.slice(0, 25) + "..." : node.label
                }}</span>
              <va-icon class="va-select__option__selected-icon" name="material-icons">
                done
              </va-icon>
            </div>
          </treeselect>
        </div>
        <div class="the-modal__buttons">
          <button class="the-modal__button the-modal__button--black" @click="onConfirmAddModal">Add</button>
          <button class="the-modal__button" @click="onCloseAddModal">Cancel</button>
        </div>
      </the-modal>
    </Portal>


  </div>
</template>

<script>
import ButtonToggle from "@/components/ui/buttons/ButtonToggle";
import Trash from "@/components/ui/icons/Trash";
import TheModal from '@/components/TheModal';
import {Portal} from "portal-vue";
import treeselect from '@riophae/vue-treeselect'
import {LEAF_PRIORITY} from "@riophae/vue-treeselect/src/constants";
import API from "@/services/API/API";
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {listToTree, listToTreeLabeled} from "@/tree";
import {showToastError} from "@/services/Helpers/HelperToast";

export default {
  name: "Permissions",

  components: {
    Trash,
    ButtonToggle,
    TheModal,
    Portal,
    treeselect
  },

  props: {
    permissions: {
      type: Array,
      default: () => []
    },
    groupPermissions: {
      type: Array,
      default: () => []
    },
    permissionsCheckboxes: {
      type: Object,
      default: () => {
      }
    },
    categoriesPermissionType: {
      type: Boolean,
      default: true
    },
    categoriesList: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      isDeleteModal: false,
      isAddModal: false,
      toDeleteId: null,
      permissionsByCategory: {},
      LEAF_PRIORITY: "",
      searchCategories: [],
      categoriesOptions: []
    }
  },

  created() {
    this.permissions.forEach(permission => {
      if (!this.permissionsByCategory[permission.categoryId]) {
        this.permissionsByCategory[permission.categoryId] = {}
        this.permissionsByCategory[permission.categoryId].name = permission.categoryName
        this.permissionsByCategory[permission.categoryId].permissions = [{
          description: permission.description,
          name: permission.name,
          id: permission.id,
          disableText: permission.disableText,
          enableText: permission.enableText
        }]
      } else {
        this.permissionsByCategory[permission.categoryId].permissions.push({
          description: permission.description,
          name: permission.name,
          id: permission.id,
          disableText: permission.disableText,
          enableText: permission.enableText
        })
      }
    })
    delete this.permissionsByCategory[4]
    const {LEAF_PRIORITY} = require("@riophae/vue-treeselect/src/constants");
    this.LEAF_PRIORITY = LEAF_PRIORITY
  },

  methods: {
    togglePermission(ids) {
      const currentPermissions = {...this.permissionsCheckboxes}

      ids.forEach(id => {
        if (currentPermissions[id]) {
          if (id === '1') {
            if (currentPermissions[7])
              delete currentPermissions[7]
          }
          delete currentPermissions[id]
        } else {
          currentPermissions[id] = true
        }
      })
      this.$emit('assignProps', 'permissionsCheckboxes', currentPermissions);
    },

    toggleCategoriesPermissionType() {
      if (this.categoriesList.length !== 0) {
        showToastError("Please remove all categories before changing", this.$toast)
        return
      }
      this.togglePermission([4, 5])
      this.searchCategories = [];
      this.$emit('assignProps', 'groupCategories', []);
      this.$emit('assignProps', 'categoriesPermissionType', !this.categoriesPermissionType);
    },

    onOpenDeleteModal(id) {
      this.toDeleteId = id;
      this.isDeleteModal = true;
    },

    onCloseDeleteModal() {
      this.isDeleteModal = false;
    },

    onConfirmDeleteModal() {
      if (this.toDeleteId === -1)
      {
        this.deleteAllCategories()
        this.isDeleteModal = false
        return
      }
      const newGroupCategories = this.categoriesList.filter(item => item.id !== this.toDeleteId)
      this.$emit('assignProps', 'groupCategories', newGroupCategories);
      this.isDeleteModal = false
    },

    onOpenAddModal() {
      this.isAddModal = true;
    },

    onCloseAddModal() {
      this.isAddModal = false;
    },

    onConfirmAddModal() {
      const newGroupCategories = this.categoriesList.length === 0 ? [] : [...this.categoriesList]
      this.searchCategories.forEach(id => {
        if (newGroupCategories.length === 0 || !newGroupCategories.find(elem => elem?.id === id)) {
          let categoryToAdd = {}
          this.categoriesOptions.find(elem => {
            const func = (elem) => {
              if (elem === undefined)
                return false
              if (elem.id === id) {
                categoryToAdd = {
                  id: elem.id,
                  label: elem.label,
                  parentId: elem.parentId
                }
                return true
              } else {
                let res = false
                if (elem?.children)
                  elem.children.forEach(item => res = res || func(item))
                return res
              }
            }

            return func(elem);
          })
          newGroupCategories.push(categoryToAdd)
        }
      })
      this.$emit('assignProps', 'groupCategories', newGroupCategories);
      this.searchCategories = []
      this.isAddModal = false
    },

    deleteAllCategories() {
      this.$emit('assignProps', 'groupCategories', []);
    },

    disableRules (id) {
      if (id === '7')
        return !this.permissionsCheckboxes?.[1]

      return false;
    },

    loadSearchCategories({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        this.handleLoadSearchCategories(searchQuery, callback, this.categoriesOptions);
      }
    },

    dFn: _.debounce((searchQuery, callback, categoriesOptions) => {
      API.APIGet(`${process.env.VUE_APP_API_URL}/category/tree-for-epackages?search=${searchQuery}`, {}, (response) => {
          const treeList = listToTreeLabeled(response)
          treeList.forEach(category => categoriesOptions.push(category))
          callback(null, treeList);
        })
    },1500),

    handleLoadSearchCategories(searchQuery, callback, categoriesOptions) {
      this.dFn(searchQuery, callback, categoriesOptions);
    }
  }
}
</script>

<style lang="scss">
.inline__buttons {
  display: flex;

  .flex:first-child {
    padding-left: 0;
  }
}

.category {

  &__header {
    font-style: normal;
    font-weight: 550;
    font-size: 20px;
    line-height: 24px;
  }

  &__item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d6dee2;
    margin-bottom: 8px;
    padding-bottom: 11px;

    &:nth-child(2) {
      margin-top: 16px;
    }

    svg {
      margin-right: 8px;
      cursor: pointer;
    }

    i {
      cursor: default;
      margin-right: 8px;
    }
  }

  &__button {
    border: 1px solid #d6dee2;
    border-radius: 6px;
    padding: 13px 24px;
    font-weight: 550;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    background: #ffffff;
    cursor: pointer;
    max-width: 240px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 16px;

    span {
      font-size: 24px;
      margin-right: 8px;
    }
  }
}

.vue-treeselect {
  &__menu {
    width: 100%;
  }

  &__control {
    border: 1px solid #f1f4f8 !important;
    border-radius: 6px !important;
    height: 48px;
  }

  &__menu {
    border-radius: 0.5rem;
    top: 4px !important;
    padding: 0;
    box-shadow: 0 2px 8px #0000001a;
    border: 0;
  }

  &__option {
    height: 45px;
    display: flex;
    align-items: center;
  }

  &__option--selected .va-icon {
    visibility: visible !important;
  }

  &__option--highlight {
    background-color: #ffffff;
  }

  &__label-container > div {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  &__checkbox-container {
    display: none !important;
  }

  .va-icon {
    visibility: hidden;
  }

  &__value-remove {
    display: none;
  }

  &__multi-value-item {
    background-color: white;
  }

  &__multi-value-label {
    padding: 0;
    color: #34495e;
    font-size: 16px;
    font-family: "Source Sans Pro", Arial, sans-serif;
  }

  &__x-container {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    color: #babfc2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.25rem;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 2rem;
    margin: auto;

    svg {
      display: none;
    }

    &::after {
      content: "";
    }
  }

  &__control-arrow-container {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    right: 0.5rem;
    color: #babfc2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.25rem;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;

    svg {
      display: none;
    }

    &::after {
      content: "";
    }
  }

  &__placeholder {
    display: block !important;
    font-size: 12px;
    line-height: 16px;
    text-transform: none;
    top: 4px;
    left: 9px;
    color: #8d9ca8 !important;
  }
}

.vue-treeselect .vue-treeselect__multi-value {
  margin-top: 11px;
  padding-left: 11px;
  margin-bottom: 0;
}

.vue-treeselect.vue-treeselect--open .vue-treeselect__control-arrow-container::after {
  transform: rotate(180deg);
}

.vue-treeselect {
  span {
    line-height: 18px;
  }
}

.vue-treeselect__input-container {
  font-size: 16px;
}
</style>
